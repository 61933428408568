export const nb = {
  intro: {
    headline: 'Weeden&shy;senteret',
    text: 'Hva er cannabis og hvordan virker det på hjernen? Scroll ned for å få vite mer.',
  },
  plant: {
    headline: 'Dette er Cannabis-planten',
    text: 'Cannabis er en fellesbetegnelse på alle produkter som kommer fra planten Cannabis sativa. Det finnes mange varianter av denne planten. Det er hovedsakelig toppskuddene og plantekvaen fra hunnplantene som brukes i cannabis.',
    cta: 'Klikk på de ulike delene av planten<br>for å lese mer',
    toppskudd: {
      title: 'Toppskudd',
      text: 'Toppskuddet er selve blomsterdelen av hunnplanten. På cannabisplanten finnes det bittesmå kjertelhår fylt med harpiks, og i kjertelhårene på toppskuddet finner vi høyest konsentrasjon av THC, virkestoffet i cannabis som gir rus. Toppskuddet kan tørkes for å lage marihuana. Plantekvaen fra toppskuddet brukes for å lage hasj.',
    },
    stilk: {
      title: 'Stilk',
      text: 'Stilken bærer planten og blomsten, og inneholder veldig lite THC.',
    },
    blad: {
      title: 'Blad',
      text: 'Bladene på planten er kjent over hele verden som et universelt symbol for cannabis, men de inneholder ofte veldig lite THC.',
    },
  },
  products: {
    headline: 'Det finnes mange cannabis-produkter',
    text: 'Cannabis er en samlebetegnelse for alle produkter som fremstilles av cannabisplanten. I tillegg finnes det kjemisk fremstilte stoffer med cannabislignende virkninger. Det er hovedsakelig toppskuddene fra hunnplanten som brukes til marihuana, men flere deler av planten inneholder også virkestoffer som brukes for å lage hasj og ulike oljer. Alle disse produktene er regulert som narkotika og er i dag forbudt å bruke, lage, kjøpe, selge eller oppbevare.',
    cta: 'Klikk på de ulike produktene<br>for å lese mer',
    hasj: {
      title: 'Hasj',
      text: 'Hasj er kvae, en seig væske fra planten, som er presset sammen med andre rester av cannabisplanten til det blir en brun klump. En liten hasjklump smuldres ofte opp og blandes med tobakk før den røykes.',
    },
    cannabisolje: {
      title: 'Cannabisolje',
      text: 'Cannabisolje er planteolje trukket ut fra cannabisplanten ved hjelp av et løsemiddel. Oljen inneholder svært mye THC og svært lite CBD. Den kan blant annet røykes, tilsettes i mat og drikkes, eller tas rent i dråpeform.',
    },
    mat: {
      title: 'Mat- og drikkevarer',
      text: 'Cannabis kan også puttes i drikke og mat (edibles), for eksempel i brownies og godteri. Tar man cannabis på denne måten, kommer ruseffekten mye senere enn ved røyking, gjerne én til tre timer etterpå, og varer som regel lenger. Det er større risiko for at man får i seg for mye cannabis fordi man ikke kjenner rusen før lenge etter inntaket.',
    },
    marihuana: {
      title: 'Marihuana',
      text: 'Marihuana, weed eller grønt, er blader og blomster fra cannabisplanten. Disse blir vanligvis tørket og kvernet opp til en krydderkonsistens, og røykes enten alene eller sammen med tobakk. THC-innholdet i marihuana varierer, men det er stort sett lavere enn i hasj.',
    },
    cbdolje: {
      title: 'CBD-olje',
      text: 'CBD-olje er olje trukket ut fra cannabisplanten. Til forskjell fra cannabisolje inneholder den stort sett bare CBD og ikke THC og skal derfor ikke gi noen rus, men dette kan variere. Alle ekstrakter av cannabisplanten, også CBD-produkter, er klassifisert som narkotika i Norge fordi det inneholder varierende mengde med THC.',
    },
    syntetisk: {
      title: 'Syntetiske cannabinoider',
      text: 'Syntetisk cannabis, eller spice, er en stor gruppe rusmidler som er kjemisk produsert og har cannabisliknende effekter. De fleste av disse stoffene har mye sterkere virkninger sammenlignet med naturlige cannabisprodukter som hasj og marihuana, og med høyere risiko for å oppleve psykose og komplikasjoner ved inntak. Kjente skadevirkninger er høyt blodtrykk, høy puls, brystsmerter, hjerteinfarkt, bevisstløshet, kramper, uro, angst, panikkanfall, akutte psykoser og forverring av tidligere stabil psykisk sykdom.',
    },
  },
  chemistry: {
    headline: 'Cannabis består av flere kjemiske komponenter',
    text: 'Cannabisplanten inneholder mange ulike kjemiske stoffer. De som virker på hjernen kaller vi cannabinoider. De mest kjente cannabinoidene er THC og CBD. Det er disse to som bestemmer store deler av den opplevde effekten ved bruk av cannabis. Disse stoffene er fettløselige og kan være i kroppen i lang tid avhengig av hvor ofte man bruker. Enkeltinntak av THC vil vanligvis kunne påvises i opptil en uke, og ved langvarige store inntak er det sjelden det kan spores i mer enn én måned. ',
    cta: 'Klikk på de ulike molekylene for å lese mer',
    thc: {
      title: 'THC',
      text: 'THC (tetrahydrocannabinol) er et psykoaktivt stoff som gir rusopplevelsen. THC forandrer måten hjernen fungerer på når man er ruset, og kan føre til at man endrer humør, bevissthet, adferd eller får en annen virkelighetsoppfatning. Ved inntak av cannabis med høyt THC-innhold øker risikoen for helseskadelige konsekvenser, som f.eks. akutt psykose.',
    },
    cbd: {
      title: 'CBD',
      text: 'CBD (cannabidiol) gir ingen rusvirkning, men det kan dempe uønskede effekter av THC som angst, hukommelsesproblemer og psykotiske symptomer. Det er fortsatt mye man ikke vet om CBD og hvilke virkninger det kan ha, men det forskes mye på dette. Alle CBD-produkter inneholder varierende mengde av THC, og er derfor regulert som narkotika i Norge.',
    },
  },
  health: {
    headline: 'Helsepåvirkning',
    text: 'Cannabis kan være skadelig for hjernen, særlig for en ung hjerne i utvikling. Normalt er ikke hjernen ferdig utviklet før du nærmer deg 25 år. Forskere er enige om at en hjerne i utvikling ikke bør utsettes for cannabis. Bruk av cannabis kan øke risikoen for depresjon og angst, særlig for sårbare personer og dersom man begynner å bruke i ung alder. Bruk av cannabis kan også føre til avhengighet.',
    cta: 'Les mer om helseeffekter<br>ved å klikke på knappene',
    korttid: {
      title: 'Korttidsvirkninger',
      text: `
          <p>De fleste som røyker hasj eller marihuana vil ikke få psykiske problemer.</p>
          <p>Det er stor forskjell i hvordan man reagerer på cannabis. Noen opplever å bli kvalme, engstelige, får hjerteklapp og panikkangst eller bli litt lei seg, mens andre vil bare føle seg avslappet, lett, lattermild eller litt sulten. Dette kan også variere fra gang til gang, og går vanligvis over etter hvert som rusen forsvinner ut av kroppen.</p>
          <p>Bruk av cannabis vil også påvirke kognitive funksjoner som oppmerksomhet og reaksjonsevne, og det er derfor man blant annet ikke skal kjøre bil når man er påvirket. Brukt sammen med andre narkotiske stoffer og alkohol kan virkningen forsterkes og gi uforutsigbare reaksjoner.</p>
        `,
    },
    langtid: {
      title: 'Langtidsvirkninger',
      text: `
          <p>Jevnlig bruk av cannabis over lang tid kan ha mer langsiktige virkninger. En av virkningene er nedsatt kognitiv funksjon, som redusert oppmerksomhet, hukommelse og evne til oppgaveløsing.</p>
          <p>Fordi unge hjerner ikke er ferdig utviklet kan slike skader bli permanente dersom man bruker mye cannabis i ung alder. Langtidsbruk kan også føre til psykiske problemer som angst og depresjon, og psykosesymptomer som hallusinasjoner og vrangforestillinger. Dette gjelder spesielt for sårbare personer. I verste fall kan cannabisbruk over lengre tid føre til langvarige psykoser som schizofreni.</p>
        `,
    },
    avhengighet: {
      title: 'Avhengighet',
      text: `
          <p>Om man starter å røyke cannabis i ung alder og bruker mye, er sjansen større for å bli avhengig. Røyker du mye får kroppen din høy toleranse, og man vil etter hvert trenge mer cannabis for å bli ruset.</p>
          <p>En avhengighet betyr at når du prøver å slutte så kan du oppleve ulike former for abstinenser. Rastløshet, søvnproblemer, angst og konsentrasjonsvansker er vanlige symptomer. Ved en avhengighet bruker man ikke nødvendigvis cannabis for rusens skyld, men for å slippe abstinenser og føle seg "frisk".</p>
          <p>Den andre siden av en avhengighet er at det kan gå ut over det som er viktig for deg i livet, og at du velger cannabis fremfor andre aktiviteter. I dag er det flere som søker seg til behandling for cannabisavhengighet enn tidligere.</p>
        `,
    },
  },
  smuggling: {
    headline: 'Hvor kommer cannabisen fra?',
    text1:
      'Opprinnelig ble planten dyrket i varmere strøk, spesielt i Asia, Nord-Afrika og Mellom-Amerika, og der dyrkes det fortsatt mye. Hasjen som beslaglegges i Norge kommer stort sett fra Marokko og smugles hit via Spania og Nederland, men en stadig større del av den cannabisen som brukes i Europa er også dyrket her. Også i Norge er det avdekket flere cannabisplantasjer.',
    text2:
      'Dyrking av cannabis kan ha store negative miljø- og samfunnsmessige konsekvenser. Det kan hindre folks tilgang på vann og bidra til utslipp av miljøskadelige kjemikalier. Den illegale cannabishandelen er en av de viktigste måtene organiserte kriminelle og terrororganisasjoner finansierer andre ulovlige aktiviteter, som for eksempel menneskehandel og kjøp av andre ulovlige stoffer og våpen.',
  },
  smugglingGame: {
    text: 'Klikk på knappen under for å styre flyet på veien mot Norge. Prøv å treffe de blå ringene. Trykk på høyre eller venstre side av skjermen for å styre.',
    cta: 'Spill',
    game: {
      text1: 'Hasjen som beslaglegges i Norge kommer stort sett fra Marokko.',
      text2: 'Den smugles hit via Spania og Nederland.',
      text3:
        'En stadig større del av den cannabisen som brukes i Europa er også dyrket her.',
      text4: 'Også i Norge er det avdekket flere cannabisplantasjer.',
    },
  },
  sale: {
    headline: 'Et sterkere rusmiddel enn tidligere',
    text: 'Hasj og marihuana har blitt et mye sterkere rusmiddel enn tidligere fordi innholdet av THC har økt. Gjennomsnittlig innhold av THC i hasj har økt fra rundt 10 % for ti år siden, til rundt 30 % i dag, men det er registrert THC-innhold helt opp til 45 %. Økt innhold av THC gir økt risiko for akutte og langsiktige helseskader. Klarer du å se styrkegraden på de ulike jointene?',
    cta: 'Klikk på en joint og se svaret',
    joints:
      'Selv om snittet i Norge allerede er høyt på 30 % har det blitt målt så høyt som 45 %.',
  },
  statistics: {
    headline: 'Hvor mange bruker cannabis i Norge?',
    text: 'Mange tror kanskje at <i>alle</i> har prøvd cannabis, og i noen miljøer er det nok mer vanlig enn i andre. Undersøkelser blant ungdom viser at 90 % ikke har brukt cannabis. Ungdata-undersøkelsen (2024) viser at på ungdomstrinnet har 5 % av guttene og 4 % av jentene brukt cannabis i løpet av det siste året. På videregående er det flere som sier at de har brukt; 19 % av guttene og 14 % av jentene svarer at de har brukt i løpet av siste året. For Oslo (2023) er tallene høyere enn ellers i landet og på VG3 i Oslo har 38 % av guttene og 25 % av jentene brukt cannabis. Ifølge en europeisk skoleundersøkelse (ESPAD) er norske ungdommers bruk av cannabis blant det laveste i Europa.',
    numbers: {
      stat1: 'av ungdom har <strong>ikke</strong> brukt',
      stat2: 'av gutter på VGS har brukt',
      stat3: 'av jenter på VGS har brukt',
    },
  },

  prohibited: {
    headline: 'Bruk av cannabis er forbudt',
    text1:
      'Narkotikaforskriften slår fast at cannabis er narkotika, mens det i legemiddelloven er bestemt at tilvirkning, anskaffelse, omsetning, innførsel, utførsel, oppbevaring, besittelse og bruk av narkotika er forbudt. Alle produkter som kan lages av cannabisplanten er regulert som narkotika og er i dag forbudt. Også kjemisk fremstilt cannabis, syntetiske cannabinoider, betegnes som narkotika og er forbudt. Brudd på legemiddelloven er straffbart. Ungdom som tas for bruk av cannabis vil ofte bli ilagt en alternativ reaksjon fra politiet. Ungdommen kan få en påtaleunnlatelse, som betyr at politiet ikke reiser straffesak, men for eksempel stiller krav om urinprøvetesting og oppfølging av sosialarbeidere. Enkelte medisinske cannabisprodukter kan foreskrives av lege til en liten gruppe pasienter som kan ha nytte av det. Dette er strengt regulert.',
    text2:
      'Flere partier på Stortinget ønsker at ansvaret for samfunnets reaksjoner på bruk og besittelse av ulovlige rusmidler til eget bruk overføres fra justissektoren til helsetjenesten. De ønsker på den måten å sikre bedre hjelp og oppfølging. Cannabis er fortsatt forbudt i dag.',
    text3:
      'Stortinget har vedtatt at rusbruk hos personer som hjelper eller tilkaller nødetatene i akutte situasjoner, som ved overdoser, ikke skal straffes av politiet.',
  },
  links: {
    headline: 'Lurer du fortsatt på noe?',
    text: 'Ønsker du å vite enda mer om cannabis og hvordan det kan påvirke hjernen og kroppen din, eller trenger du bare noen å prate med? Her gir vi deg noen nyttige lenker du kan bruke.',
    readmore: 'Les mer',
    ung: 'Offentlig og kvalitetssikret informasjon for ungdom. Også informasjon om cannabis og andre rusmidler. Du kan stille spørsmål og får svar på det du lurer på.',
    rustelefonen:
      'Landsdekkende informasjonstjeneste om rusmiddelproblematikk. Har mye informasjon om cannabis. Du kan ta anonym kontakt via telefon, chat eller spørreskjema.',
    mentalhelse:
      'En interesseorganisasjon for ungdom som jobber med forebygging av psykiske plager. Hvis du har behov for noen å snakke med kan du ta kontakt på deres chat-tjeneste.',
    helsenorge:
      'Et offentlig nettsted for helseinformasjon til alle. Har også informasjon om cannabis.',
  },
  about: {
    headline: 'Om kampanjen',

    text: `<p>Weedensenteret.no er et digitalt vitensenter for cannabis. Vårt mål er å få frem nyansert og faktabasert kunnskap. Vi håper å kunne bevisstgjøre ungdom på når og hvordan rusmidler kan utgjøre et problem – både for seg selv og vennene rundt dem.</p>
      <p>Kunnskapsgrunnlaget for nettsiden baserer seg hovedsakelig på <a href=" https://www.universitetsforlaget.no/cannabisboka-1" target="_blank">Cannabisboka (2019)</a>, rapporter og internasjonalt anerkjent litteratur. Cannabisboka viser til den nyeste forskningen på cannabis. I tillegg har vi hentet informasjon fra RUSinfo, Folkehelseinstituttet og Oslo universitetssykehus. I valg av innhold på nettsiden har vi også lent oss på innsiktsarbeid i målgruppen og en referansegruppe bestående av representanter fra bl.a. skole, helse og politi.</p>`,
  },
  cookies: {
    short:
      'Denne nettsiden benytter <a href="https://www.helsedirektoratet.no/om-oss/om-nettstedet/besoksstatistikk-og-informasjonskapsler" target="_blank" rel="noopener">informasjonskapsler</a> (cookies).',
    long: 'Weedensenteret er en kampanje som skal informere og bevisstgjøre på når og hvordan rusmidler kan bli et problem. Vi minner om at alle produkter av cannabisplanten er regulert som narkotika og er forbudt å bruke, lage, kjøpe, selge eller oppbevare.',
  },
  ui: {
    close: 'Lukk',
    about: 'Om',
    videos: 'Se filmer',
  },
  contentmenu: {
    forsiden: 'Forsiden',
    planten: 'Planten',
    produkter: 'Produkter',
    kjemi: 'Kjemi',
    helse: 'Helsepåvirkninger',
    smugling: 'Smugling',
    salg: 'Salg',
    statistikk: 'Statistikk',
    lovverk: 'Lovverk',
    lenker: 'Lenker',
  },
};
